<template>
    <div class="card">
        <Divider align="left" class="mb-6 mt-0">
            <div class="inline-flex align-items-center">
                <!-- <router-link :to="{ name: 'details', params: { slug: route.params.slug, id: route.params.id} }">
                    <Button v-if="backButton" icon="pi pi-arrow-left" class="p-button-raised p-button-plain p-button-text px-5  mr-2"/>
                </router-link> -->
                <b class="uppercase text-600 text-xl">Tour booking</b>
            </div>
        </Divider>
        <form @submit.prevent="booking(!v$.$invalid)" v-if="(state.new_user != 0) || (user != null)" class="px-2">
            <div class="p-fluid grid" v-if="pageLoading">
                <div class="field col-12 lg:col-4"><Skeleton height="4rem"/></div>
                <div class="field col-12 lg:col-4"><Skeleton height="4rem"/></div>
                <div class="field col-12 lg:col-4"><Skeleton height="4rem"/></div>
            </div>
            <div class="p-fluid grid" v-else>
                <div class="field col-12 lg:col-3">
                    <span class="p-float-label p-fluid">
                        <InputText
                            id="travellerName"
                            type="text"
                            :class="{ 'p-invalid': v$.travellerName.$invalid && bookingSubmitted, }"
                            v-model="v$.travellerName.$model"
                        />
                        <label for="travellerName">Traveller name</label>
                    </span>
                    <span v-if="v$.travellerName.$error && bookingSubmitted">
                        <span
                            id="travellerName-error"
                            v-for="(error, index) of v$.travellerName.$errors"
                            :key="index"
                        >
                            <small class="p-error">{{error.$message.replace("Value", "Traveller Name")}}</small>
                        </span>
                    </span>
                    <small
                        v-else-if="(v$.travellerName.$invalid && bookingSubmitted) || v$.travellerName.$pending.$response"
                        class="p-error"
                    >
                        {{ v$.travellerName.required.$message.replace("Value", "Traveller Name") }}
                    </small>
                </div>
                <div class="field col-12 lg:col-3">
                    <span class="p-float-label p-fluid">
                        <InputText
                            id="travellerMobile"
                            type="text"
                            :class="{ 'p-invalid': v$.travellerMobile.$invalid && bookingSubmitted, }"
                            v-model="v$.travellerMobile.$model"
                        />
                        <label for="travellerMobile">Traveller mobile</label>
                    </span>
                    <span v-if="v$.travellerMobile.$error && bookingSubmitted">
                        <span
                            id="travellerMobile-error"
                            v-for="(error, index) of v$.travellerMobile.$errors"
                            :key="index"
                        >
                            <small class="p-error">{{error.$message.replace("Value", "Traveller Mobile")}}</small>
                        </span>
                    </span>
                    <small
                        v-else-if="(v$.travellerMobile.$invalid && bookingSubmitted) || v$.travellerMobile.$pending.$response"
                        class="p-error"
                    >
                        {{ v$.travellerMobile.required.$message.replace("Value", "Traveller Mobile") }}
                    </small>
                </div>
                <div class="field col-12 lg:col-3">
                    <span class="p-float-label p-fluid">
                        <InputText
                            id="travellerEmail"
                            type="text"
                            :class="{ 'p-invalid': v$.travellerEmail.$invalid && bookingSubmitted, }"
                            v-model="v$.travellerEmail.$model"
                        />
                        <label for="travellerEmail">Traveller email</label>
                    </span>
                    <span v-if="v$.travellerEmail.$error && bookingSubmitted">
                        <span
                            id="travellerEmail-error"
                            v-for="(error, index) of v$.travellerEmail.$errors"
                            :key="index"
                        >
                            <small class="p-error">{{error.$message.replace("Value", "Traveller E-mail")}}</small>
                        </span>
                    </span>
                    <small
                        v-else-if="(v$.travellerEmail.$invalid && bookingSubmitted) || v$.travellerEmail.$pending.$response"
                        class="p-error"
                    >
                        {{ v$.travellerEmail.required.$message.replace("Value", "Traveller E-mail") }}
                    </small>
                </div>
                <div class="field col-12 lg:col-3" v-if="state.new_user == 1">
                    <span class="p-float-label p-fluid">
                        <Password
                            id="traveller_password"
                            :class="{ 'p-invalid': v$.traveller_password.$invalid && bookingSubmitted, }"
                            v-model="v$.traveller_password.$model"
                            :feedback="false"
                            toggleMask
                        />
                        <label for="traveller_password">Traveller password</label>
                    </span>
                    <span v-if="v$.traveller_password.$error && bookingSubmitted">
                        <span
                            id="traveller_password-error"
                            v-for="(error, index) of v$.traveller_password.$errors"
                            :key="index"
                        >
                            <small class="p-error">{{error.$message.replace("Value", "Traveller Password")}}</small>
                        </span>
                    </span>
                    <small
                        v-else-if="(v$.traveller_password.$invalid && bookingSubmitted) || v$.traveller_password.$pending.$response"
                        class="p-error"
                    >
                        {{ v$.traveller_password.required.$message.replace("Value", "Traveller Password") }}
                    </small>
                </div>
            </div>
            <div class="p-fluid grid">
                <div class="col-12 lg:col-6">
                    <Divider align="right">
                        <b class="uppercase text-500">Adults</b>
                    </Divider>
                    <div class="p-fluid grid" v-if="pageLoading">
                        <div class="field col-12 lg:col-6"><Skeleton height="4rem"/></div>
                        <div class="field col-12 lg:col-6"><Skeleton height="4rem"/></div>
                    </div>
                    <div class="p-fluid grid" v-else>
                        <div class="field col-12 lg:col-6">
                            <span class="p-float-label p-fluid">
                                <InputNumber
                                    id="numberOfAdults"
                                    showButtons
                                    :step="1"
                                    :min="0"
                                    :max="maxAdultsSeatsAvailable"
                                    incrementButtonIcon="pi pi-plus"
                                    decrementButtonIcon="pi pi-minus"
                                    :class="{ 'p-invalid': v$.numberOfAdults.$invalid && bookingSubmitted, }"
                                    v-model="v$.numberOfAdults.$model"
                                />
                                <label for="numberOfAdults">Number of Adults</label>
                            </span>
                            <span v-if="v$.numberOfAdults.$error && bookingSubmitted">
                                <span
                                    id="numberOfAdults-error"
                                    v-for="(error, index) of v$.numberOfAdults.$errors"
                                    :key="index"
                                >
                                    <small class="p-error">{{error.$message.replace("Value", "Number of Adults")}}</small>
                                </span>
                            </span>
                            <small
                                v-else-if="(v$.numberOfAdults.$invalid && bookingSubmitted) || v$.numberOfAdults.$pending.$response"
                                class="p-error"
                            >
                                {{ v$.numberOfAdults.required.$message.replace("Value", "Number of Adults") }}
                            </small>
                        </div>

                        <div class="field col-12 lg:col-6">
                            <span class="p-float-label p-fluid">
                                <InputNumber
                                    id="price_per_person"
                                    :class="{ 'p-invalid': v$.finalUnitPriceAdults.$invalid && bookingSubmitted, }"
                                    showButtons
                                    :min="0"
                                    incrementButtonIcon="pi pi-plus"
                                    decrementButtonIcon="pi pi-minus"
                                    v-model="v$.finalUnitPriceAdults.$model"
                                />
                                <label for="price_per_person">Price Per Person</label>
                            </span>
                            <span v-if="v$.finalUnitPriceAdults.$error && bookingSubmitted">
                                <span
                                    id="finalUnitPriceAdults-error"
                                    v-for="(error, index) of v$.finalUnitPriceAdults.$errors"
                                    :key="index"
                                >
                                    <small class="p-error">{{error.$message}}</small>
                                </span>
                            </span>
                            <small
                                v-else-if="(v$.finalUnitPriceAdults.$invalid && bookingSubmitted) || v$.finalUnitPriceAdults.$pending.$response"
                                class="p-error"
                            >
                                {{ v$.finalUnitPriceAdults.required.$message.replace("Value", "Final Unit Price Adults") }}
                            </small>
                        </div>

                        <div class="col-4">
                            <Fieldset>
                                <template #legend>
                                    <div class="font-light text-sm text-700">Original price</div>
                                </template>
                                <div class="font-medium">BDT {{ state.originalUnitPriceAdults.toLocaleString('en-BD') }}</div>
                            </Fieldset>
                        </div>
                        <div class="col-4">
                            <Fieldset>
                                <template #legend>
                                    <div class="font-light text-sm text-700">Final price</div>
                                </template>
                                <div class="font-semibold">BDT {{ state.finalUnitPriceAdults.toLocaleString('en-BD') }}</div>
                            </Fieldset>
                        </div>
                        <div class="col-4">
                            <Fieldset>
                                <template #legend>
                                    <div class="font-light text-sm text-700">Total price</div>
                                </template>
                                <div class="font-semibold">BDT {{ state.totalPriceAdults.toLocaleString('en-BD') }}</div>
                            </Fieldset>
                        </div>
                    </div>
                </div>
                <div class="col-12 lg:col-6">
                    <Divider align="left">
                        <b class="uppercase text-500">Children</b>
                    </Divider>
                    <div class="p-fluid grid" v-if="pageLoading">
                        <div class="field col-12 lg:col-6"><Skeleton height="4rem"/></div>
                        <div class="field col-12 lg:col-6"><Skeleton height="4rem"/></div>
                    </div>
                    <div class="p-fluid grid" v-else>
                        <div class="field col-12 lg:col-6">
                            <span class="p-float-label p-fluid mb-0">
                                <InputNumber
                                    id="numberOfChildren"
                                    showButtons
                                    :step="1"
                                    :min="0"
                                    :max="maxChildrenSeatsAvailable"
                                    incrementButtonIcon="pi pi-plus"
                                    decrementButtonIcon="pi pi-minus"
                                    :class="{ 'p-invalid': v$.numberOfChildren.$invalid && bookingSubmitted, }"
                                    v-model="v$.numberOfChildren.$model"
                                    :disabled="tour.price_children==0"
                                />
                                <label for="numberOfChildren">Number of Children</label>
                            </span>
                            <span v-if="v$.numberOfChildren.$error && bookingSubmitted">
                                <span
                                    id="numberOfChildren-error"
                                    v-for="(error, index) of v$.numberOfChildren.$errors"
                                    :key="index"
                                >
                                    <small class="p-error">{{error.$message.replace("Value", "Number of Children")}}</small>
                                </span>
                            </span>
                            <small
                                v-else-if="(v$.numberOfChildren.$invalid && bookingSubmitted) || v$.numberOfChildren.$pending.$response"
                                class="p-error"
                            >
                                {{ v$.numberOfChildren.required.$message.replace("Value", "Number of Children") }}
                            </small>
                        </div>
                        
                        <div class="field col-12 lg:col-6">
                            <span class="p-float-label p-fluid mb-0">
                                <InputNumber
                                    id="price_per_children"
                                    showButtons
                                    :min="0"
                                    incrementButtonIcon="pi pi-plus"
                                    decrementButtonIcon="pi pi-minus"
                                    :class="{ 'p-invalid': v$.finalUnitPriceChildren.$invalid && bookingSubmitted, }"
                                    v-model="v$.finalUnitPriceChildren.$model"
                                    :disabled="tour.price_children==0"
                                />
                                <label for="price_per_children">Price Per Children</label>
                            </span>
                            <span v-if="v$.finalUnitPriceChildren.$error && bookingSubmitted">
                                <span
                                    id="finalUnitPriceChildren-error"
                                    v-for="(error, index) of v$.finalUnitPriceChildren.$errors"
                                    :key="index"
                                >
                                    <small class="p-error">{{error.$message}}</small>
                                </span>
                            </span>
                            <small
                                v-else-if="(v$.finalUnitPriceChildren.$invalid && bookingSubmitted) || v$.finalUnitPriceChildren.$pending.$response"
                                class="p-error"
                            >
                                {{ v$.finalUnitPriceChildren.required.$message.replace("Value", "Final Unit Price Children") }}
                            </small>
                        </div>

                        <div class="col-4">
                            <Fieldset>
                                <template #legend>
                                    <div class="font-light text-sm text-700">Original price</div>
                                </template>
                                <div class="font-medium">BDT {{ state.originalUnitPriceChildren.toLocaleString('en-BD') }}</div>
                            </Fieldset>
                        </div>
                        <div class="col-4">
                            <Fieldset>
                                <template #legend>
                                    <div class="font-light text-sm text-700">Final price</div>
                                </template>
                                <div class="font-semibold">BDT {{ state.finalUnitPriceChildren.toLocaleString('en-BD') }}</div>
                            </Fieldset>
                        </div>
                        <div class="col-4">
                            <Fieldset>
                                <template #legend>
                                    <div class="font-light text-sm text-700">Total price</div>
                                </template>
                                <div class="font-semibold">BDT {{ state.totalPriceChildren.toLocaleString('en-BD') }}</div>
                            </Fieldset>
                        </div>
                    </div>
                </div>
            </div>
            <div v-if="tour.tshirt && (state.numberOfAdults + state.numberOfChildren) > 0">
                <div class="col-12">
                    <Divider align="right">
                        <b class="uppercase text-500">T-Shirt Sizes</b>
                    </Divider>
                </div>
                <div class="p-fluid grid" v-if="pageLoading">
                    <div class="field col-6 xl:col-2"><Skeleton height="4rem"/></div>
                </div>
                <div class="p-fluid grid" v-else>
                    <div class="field col-6 xl:col-2 mb-0" v-for="(n, i) in (state.numberOfAdults + state.numberOfChildren)" :key="i">
                        <span class="p-float-label">
                            <Dropdown
                                id="t-shirt"
                                v-model="state.tshirts[i]"
                                :options="tshirtSizes"
                                showClear
                            />
                            <label for="t-shirt">T-shirt</label>
                        </span>
                    </div>
                </div>
            </div>
            <Divider align="right" class="mb-4">
                <b class="uppercase text-500">Cost</b>
            </Divider>
            <div class="p-fluid grid" v-if="pageLoading">
                <div class="field col-12 mb-0"><Skeleton height="4rem"/></div>
                <div class="field col-12 mb-0"><Skeleton height="4rem"/></div>
                <div class="field col-12 mb-0"><Skeleton height="4rem"/></div>
            </div>
            <template v-else>
                <div class="p-fluid grid mb-4">
                    <div class="col-12 lg:col-3">
                        <div class="card m-2">
                            <div>Subtotal Price</div>
                            <div class="flex align-items-end flex-wrap font-normal text-xl">
                                <span class="flex align-items-end justify-content-center text-primary mr-1">BDT</span>
                                <span class="flex align-items-end justify-content-center text-primary mx-1">{{ state.subTotalPrice.toLocaleString('en-BD') }}</span>
                            </div>
                        </div>
                    </div>
                    <div class="col-12 lg:col-6">
                        <div class="card m-2">
                            <Button label="Apply Discount Coupon" class="p-button-text p-button-info" @click="couponInput = true" v-if="!couponInput" />
                            <div class="flex-grow-1 flex align-items-center" v-else>
                                <div class="w-full">
                                    <template v-if="coupon == null">
                                        <div class="p-inputgroup">
                                            <Button label="Apply" class="p-button-info" :loading="couponLoading" @click.prevent="applyCoupon(!c$.$invalid)" />
                                            <InputText
                                                class="p-inputtext-sm"
                                                placeholder="Coupon code"
                                                :class="{ 'p-invalid': c$.couponCode.$invalid && couponSubmitted, }"
                                                v-model="c$.couponCode.$model"
                                            />
                                            <Button icon="pi pi-times" class="p-button-secondary" @click="closeCouponInput" />
                                        </div>
                                        <span v-if="c$.couponCode.$error && couponSubmitted">
                                            <span
                                                id="couponCode-error"
                                                v-for="(error, index) of c$.couponCode.$errors"
                                                :key="index"
                                            >
                                                <small class="p-error">{{error.$message.replace("Value", "Coupon Code")}}</small>
                                            </span>
                                        </span>
                                        <small
                                            v-else-if="(c$.couponCode.$invalid && couponSubmitted) || c$.couponCode.$pending.$response"
                                            class="p-error"
                                        >
                                            {{ c$.couponCode.required.$message.replace("Value", "Coupon Code") }}
                                        </small>
                                    </template>
                                    <template v-else>
                                        <div class="flex-grow-1 flex flex-wrap justify-content-between align-items-center">
                                            <div class="flex flex-column">
                                                <div class="mb-1">Coupon applied - <span class="font-semibold">{{ coupon.code }}</span></div>
                                                <div class="text-primary mb-1">Get {{ coupon.coupon_discount }}% discount (upto BDT {{ coupon.limit.toLocaleString('en-BD') }})</div>
                                                <div class="font-light text-sm">*On spending BDT {{ coupon.threshold.toLocaleString('en-BD') }} or more</div>
                                            </div>
                                            <div class="flex">
                                                <span class="p-float-label p-fluid mb-0">
                                                    <InputNumber
                                                        id="discount"
                                                        showButtons
                                                        :min="0"
                                                        incrementButtonIcon="pi pi-plus"
                                                        decrementButtonIcon="pi pi-minus"
                                                        v-model="coupon.discount"
                                                    />
                                                    <label for="numberOfChildren">Discount (%)</label>
                                                </span>
                                            </div>
                                            <div class="flex">
                                                <Button label="Remove" class="p-button-outlined p-button-sm p-button-danger mx-1" @click="removeCoupon" />
                                            </div>
                                        </div>
                                    </template>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-12 lg:col-3">
                        <div class="card m-2">
                            <div>Total Price</div>
                            <div class="flex align-items-end flex-wrap font-semibold text-xl">
                                <span class="flex align-items-end justify-content-center text-primary mr-1">BDT</span>
                                <span class="flex align-items-end justify-content-center text-primary mx-1">{{ state.totalPrice.toLocaleString('en-BD') }}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </template>
            <Divider align="right" class="mb-4">
                <b class="uppercase text-500">Payment</b>
            </Divider>
            <div class="p-fluid grid" v-if="pageLoading">
                <div class="field col-12 lg:col-4"><Skeleton height="4rem"/></div>
                <div class="field col-12 lg:col-4"><Skeleton height="4rem"/></div>
            </div>
            <div class="p-fluid grid" v-else>
                <div class="field col-12 lg:col-4">
                    <span class="p-float-label p-fluid mb-0">
                        <Dropdown
                            id="payment_method"
                            :options="paymentMethod"
                            optionLabel="name"
                            optionValue="value"
                            :class="{ 'p-invalid': v$.payment_method.$invalid && bookingSubmitted, }"
                            v-model="v$.payment_method.$model"
                            showClear
                            disabled
                        />
                        <label for="payment_method">Payment method</label>
                    </span>
                    <span v-if="v$.payment_method.$error && bookingSubmitted">
                        <span
                            id="payment_method-error"
                            v-for="(error, index) of v$.payment_method.$errors"
                            :key="index"
                        >
                            <small class="p-error">{{error.$message.replace("Value", "Payment method")}}</small>
                        </span>
                    </span>
                    <small
                        v-else-if="(v$.payment_method.$invalid && bookingSubmitted) || v$.payment_method.$pending.$response"
                        class="p-error"
                    >
                        {{ v$.payment_method.required.$message.replace("Value", "Payment method") }}
                    </small>
                </div>
                <div class="field col-12 lg:col-4">
                    <span class="p-float-label p-fluid mb-0">
                        <InputNumber
                            id="total_paid"
                            showButtons
                            :min="0"
                            :max="state.totalPrice"
                            incrementButtonIcon="pi pi-plus"
                            decrementButtonIcon="pi pi-minus"
                            :class="{ 'p-invalid': v$.total_paid.$invalid && bookingSubmitted, }"
                            v-model="v$.total_paid.$model"
                        />
                        <label for="total_paid">Total paid</label>
                    </span>
                    <span v-if="v$.total_paid.$error && bookingSubmitted">
                        <span
                            id="total_paid-error"
                            v-for="(error, index) of v$.total_paid.$errors"
                            :key="index"
                        >
                            <small class="p-error">{{error.$message.replace("Value", "Total paid")}}</small>
                        </span>
                    </span>
                    <small
                        v-else-if="(v$.total_paid.$invalid && bookingSubmitted) || v$.total_paid.$pending.$response"
                        class="p-error"
                    >
                        {{ v$.total_paid.required.$message.replace("Value", "Total paid") }}
                    </small>
                </div>
                <div class="col-12 lg:col-4">
                    <!-- <Fieldset>
                        <template #legend>
                            <div class="font-light text-sm text-700">Due</div>
                        </template>
                        <span class="font-medium">BDT {{ state.total_due.toLocaleString('en-BD') }}</span>
                    </Fieldset> -->
                    <!-- <div class="card py-2 border-round-xs">
                        <div>Due</div>
                        <div class="flex align-items-end flex-wrap font-normal text-xl">
                            <span class="flex align-items-end justify-content-center text-primary mr-1">BDT</span>
                            <span class="flex align-items-end justify-content-center text-primary mx-1">{{ state.total_due.toLocaleString('en-BD') }}</span>
                        </div>
                    </div> -->
                    <span class="p-float-label p-fluid">
                        <InputText
                            id="due"
                            :modelValue="state.total_due.toLocaleString('en-BD')"
                            readonly
                        />
                        <label for="due">Due</label>
                    </span>
                </div>
            </div>
            <div class="p-fluid grid">
                <div class="field col-12">
                    <div class="flex justify-content-end mt-3">
                        <!-- <div class="flex">
                            <Skeleton height="4rem" v-if="pageLoading" />
                            <Button
                                class="p-button-raised p-button-danger p-button-text m-1"
                                label="Cancel"
                                icon="pi pi-times"
                                @click="cancel"
                                v-else
                            />
                        </div> -->
                        <div class="flex">
                            <Skeleton height="4rem" v-if="pageLoading" />
                            <Button
                                class="p-button-raised m-1 px-4"
                                type="submit"
                                label="Create"
                                icon="pi pi-check"
                                :loading="bookingLoading"
                                v-else
                            />
                        </div>
                    </div>
                </div>
            </div>
        </form>
        <template v-else>
            <Skeleton class="mb-2" height="4rem" v-if="pageLoading"/>
            <div  v-else class="m-8">
                <div class="grid">
                    <div class="col-12 lg:col-5 flex align-items-center justify-content-center">
                        <div class="p-fluid">
                            <div class="p-inputgroup py-8">
                                <span class="p-float-label p-fluid">
                                    <InputText id="inputtext-right" type="text" v-model="searchByEmail" />
                                    <label for="inputtext-right">Search By E-mail</label>
                                </span>
                                <Button icon="pi pi-search" @click="getUserByEmail"/>
                            </div>
                        </div>
                    </div>
                    <div class="col-12 lg:col-2">
                        <Divider layout="vertical">
                            <b>OR</b>
                        </Divider>
                    </div>
                    <div class="col-12 lg:col-5 flex align-items-center justify-content-center">
                        <Button label="New User" icon="pi pi-user-plus" class="p-button-success" @click="newUser"></Button>
                    </div>
                </div>
            </div>
        </template>
    </div>
</template>

<script>
import { ref, reactive, computed, watch } from "vue";
import { useRoute, useRouter } from 'vue-router'
import { required, requiredIf, minLength, email, integer, numeric, minValue } from "@vuelidate/validators";
import { useVuelidate } from "@vuelidate/core";
import { useToast } from 'primevue/usetoast';
import Axios from 'axios';

export default {
    setup() {
        const route = useRoute();
        const router = useRouter();
        const toast = useToast();

        const tour = ref();

        const pageLoading = ref(true);
        const user = ref(null);
        const searchByEmail = ref(null);
        const backButton = ref(true);

        const getTourDetails = () => {
            Axios
                .get("api/operator/tour/view/"+route.params.slug)
                .then((res) =>{
                    if (res.data.response == "success") {
                        tour.value = res.data.data;
                        state.originalUnitPriceAdults = parseInt(tour.value.price_adults);
                        state.discounted_price_adults = parseInt(tour.value.discounted_price_adults);
                        state.finalUnitPriceAdults = tour.value.discounted_price_adults > 0 ? parseInt(tour.value.discounted_price_adults) : parseInt(tour.value.price_adults);
                        state.originalUnitPriceChildren = parseInt(tour.value.price_children);
                        state.discounted_price_children = parseInt(tour.value.discounted_price_children);
                        state.finalUnitPriceChildren = tour.value.discounted_price_children > 0 ? parseInt(tour.value.discounted_price_children) : parseInt(tour.value.price_children);
                    }else{
                        console.log(res);
                        showToast("error", "Error", res.data.message);
                    }
                    pageLoading.value = false;
                })
                .catch((err) =>{
                    showToast("error", "Error", "Something went wrong. Try again!");
                    console.log(err);
                    pageLoading.value = false;
                });
        };
        getTourDetails();

        const getUserByEmail = () => {
            Axios
                .get("api/operator/data/user-by-email",{
                    params: {
                        email: searchByEmail.value
                    }
                })
                .then((res) => {
                    if(res.data.response == "success"){
                        user.value = res.data.data;
                        if(user.value != null){
                            state.user_id = parseInt(user.value.id);
                            state.travellerName = user.value.name;
                            state.travellerEmail = user.value.email;
                            state.travellerMobile = user.value.mobile ? formatMobileNumber(user.value.mobile) : '';
                            backButton.value = !backButton.value;
                        }
                    }else{
                        console.log(res);
                        showToast("error", "Error", res.data.message);
                    }
                    pageLoading.value = false;
                })
                .catch((err) => {
                    showToast("error", "Error", "Something went wrong. Try again!");
                    console.log(err);
                    pageLoading.value = false;
                });
            pageLoading.value = false;
        };

        const newUser = () => {
            state.new_user = 1;
            backButton.value = !backButton.value;
        }

        const paymentMethod = ref([
            {name: 'Cash', value: 'Cash'},
            // {name: 'Online', value: 'Online'},
        ]);
        const tshirtSizes = ref(["XXL", "XL", "L", "M", "S", "XS"]);

        const couponInput = ref(false);
        const couponLoading = ref(false);
        const couponSubmitted = ref(false);
        const couponShowMessage = ref(false);
        const couponApplied = ref(false);
        const coupon = ref(null);
        const couponState = reactive({
            couponCode: null,
        });
        const couponRules = {
            couponCode: { required },
        };
        const c$ = useVuelidate(couponRules, couponState);

        const bookingLoading = ref(false);
        const bookingSubmitted = ref(false);
        const bookingShowMessage = ref(false);

        const state = reactive({
            bookingType: "Operator",
            tour_id: null,
            new_user: 0,
            user_id: null,
            travellerName: null,
            travellerMobile: null,
            travellerEmail: null,
            traveller_password: null,
            numberOfAdults: 0,
            originalUnitPriceAdults: tour.value ? tour.value.price_adults : '',
            discounted_price_adults: null,
            finalUnitPriceAdults: tour.value ? (tour.value.discounted_price_adults > 0 ? tour.value.discounted_price_adults : tour.value.price_adults) : '',
            totalPriceAdults: 0,
            numberOfChildren: 0,
            originalUnitPriceChildren: tour.value ? tour.value.price_children : '',
            discounted_price_children: null,
            finalUnitPriceChildren: tour.value ? (tour.value.discounted_price_children > 0 ? tour.value.discounted_price_children : tour.value.price_children) : '',
            totalPriceChildren: 0,
            subTotalPrice: 0,
            totalPrice: 0,
            total_paid: 0,
            total_due: 0,
            tshirts: [],
            payment_method: "Cash",
            coupon_discount: null,
        });
        const bookingRules = {
            user_id : {required: requiredIf(
				function() {
					return state.new_user == 0;
				}
			)},
            discounted_price_adults: {},
            discounted_price_children: {},
            bookingType: { required },
            travellerName: { required, minLength: minLength(4) },
            travellerMobile: { required, minLength: minLength(4) },
            travellerEmail: { required, email },
            traveller_password: { required: requiredIf(
                function(){
                    return state.new_user == 1;
                }
            ) },
            numberOfAdults: { required, integer, minValue: minValue(1) },
            finalUnitPriceAdults: { required, numeric, minValue: minValue(0) },
            numberOfChildren: { required, integer, minValue: minValue(0) },
            finalUnitPriceChildren: { required, numeric, minValue: minValue(0) },
            total_paid: { required, numeric, minValue: minValue(0) },
            payment_method: { required }
        };
        const v$ = useVuelidate(bookingRules, state);

        const openCouponInput = () => {
            toggleCouponInput();
            couponInput.value = true;
        };

        const closeCouponInput = () => {
            couponInput.value = false;
            toggleCouponInput();
        };

        const toggleCouponInput = () => {
            couponSubmitted.value = false;
            couponShowMessage.value = false;
            couponApplied.value = false;
            coupon.value = null;
            couponState.couponCode = null;
        };

        const applyCoupon = (isFormValid) => {
            couponSubmitted.value = true;
            if (!isFormValid) {
                return;
            }
            couponShowMessage.value = !couponShowMessage.value;
            couponLoading.value = true;
            Axios
                .get("/api/coupon/apply", {
                    params: {
                        agency: tour.value.agency_id,
                        code: couponState.couponCode,
                        amount: state.subTotalPrice
                    }
                })
                .then((res) => {
                    if (res.data.response == "success") {
                        coupon.value = res.data.data;
                        coupon.value.coupon_discount = coupon.value.discount;
                        showToast("success", "Success", "Coupon applied");
                        updatePrice();
                    } else {
                        showToast("warn", "Warning", res.data.message);
                    }
                    couponLoading.value = false;
                })
                .catch((err) => {
                    console.log(err);
                    couponLoading.value = false;
                    showToast("error", "Error", "Could not apply coupon. Try again!");
                });
        }

        const removeCoupon = () => {
            closeCouponInput();
            updatePrice();
        }

        const maxAdultsSeatsAvailable = computed(() => {
            let seat_available = tour.value? (tour.value.seat_total - tour.value.seat_booked) - state.numberOfChildren : '';
            return seat_available;
        });

        const maxChildrenSeatsAvailable = computed(() => {
            let seat_available = tour.value ? (tour.value.seat_total - tour.value.seat_booked) - state.numberOfAdults : '';
            return seat_available;
        });

        const calculateDiscount = () => {
            if(coupon.value != null) {
                if(state.subTotalPrice < coupon.value.threshold){
                    return 0;
                }
                else{
                    let discount = state.subTotalPrice * (coupon.value.discount / 100);
                    if(discount >= coupon.value.limit) {
                        discount = coupon.value.limit;
                        // updatePrice();
                    }
                    return discount;
                }
            }
            return 0;
        };

        watch(state, () => {
            updatePrice();
        });

        const cancel = () => {
            user.value = null;
            searchByEmail.value = null;
            state.tour_id = null;
            state.new_user = 0;
            state.user_id = null;
            state.travellerName = null;
            state.travellerMobile = null;
            state.travellerEmail = null;
            state.traveller_password = null;
            state.numberOfAdults = 0;
            state.originalUnitPriceAdults = tour.value ? tour.value.price_adults : '';
            state.discounted_price_adults = tour.value.discounted_price_adults;
            state.finalUnitPriceAdults = tour.value ? (tour.value.discounted_price_adults > 0 ? tour.value.discounted_price_adults : tour.value.price_adults) : '';
            state.totalPriceAdults = 0;
            state.numberOfChildren = 0;
            state.originalUnitPriceChildren = tour.value ? tour.value.price_children : '';
            state.discounted_price_children = tour.value.discounted_price_children;
            state.finalUnitPriceChildren = tour.value ? (tour.value.discounted_price_children > 0 ? tour.value.discounted_price_children : tour.value.price_children) : '';
            state.totalPriceChildren = 0;
            state.subTotalPrice = 0;
            state.totalPrice = 0;
            state.total_paid = 0;
            backButton.value = !backButton.value;
        }

        const updatePrice = () => {
            // if (state.discounted_price_adults>0) {
            //     state.finalUnitPriceAdults = state.discounted_price_adults;
            // }else{
            //     state.finalUnitPriceAdults = tour.value.price_adults;
            // }
            state.totalPriceAdults = state.numberOfAdults * state.finalUnitPriceAdults;
            state.finalUnitPriceChildren = state.discounted_price_children;
            state.totalPriceChildren = state.numberOfChildren * state.finalUnitPriceChildren;
            state.subTotalPrice = state.totalPriceAdults + state.totalPriceChildren;
            state.totalPrice = state.subTotalPrice - calculateDiscount();
            if(tour.value.tshirt) state.tshirts.length = state.numberOfAdults + state.numberOfChildren;
            state.total_due = state.totalPrice - state.total_paid;
        }

        const booking = (isFormValid) => {
            bookingSubmitted.value = true;
            if (!isFormValid) {
                return;
            }
            bookingShowMessage.value = !bookingShowMessage.value;
            bookingLoading.value = true;

            console.log(state);
            console.log(router);

            const formData = new FormData();

            formData.append("tour_id", tour.value.id);
            formData.append("new_user", state.new_user);
            if(state.user_id) formData.append("user_id", state.user_id);
            formData.append("traveller_name", state.travellerName);
            formData.append("traveller_mobile", state.travellerMobile);
            formData.append("traveller_email", state.travellerEmail);
            formData.append("traveller_password", state.traveller_password);
            formData.append("number_of_adults", state.numberOfAdults);
            formData.append("original_unit_price_adults", state.originalUnitPriceAdults);
            formData.append("final_unit_price_adults", state.finalUnitPriceAdults);
            formData.append("total_price_adults", state.totalPriceAdults);
            formData.append("number_of_children", state.numberOfChildren);
            formData.append("original_unit_price_children", state.originalUnitPriceChildren);
            formData.append("final_unit_price_children", state.finalUnitPriceChildren);
            formData.append("total_price_children", state.totalPriceChildren);
            formData.append("sub_total_price", state.subTotalPrice);
            if(coupon.value != null) formData.append("coupon_id", coupon.value.id);
            if(coupon.value != null) formData.append("coupon_discount", coupon.value.coupon_discount);
            formData.append("total_price", state.totalPrice);
            formData.append("total_paid", state.total_paid);
            formData.append("payment_method", state.payment_method);
            formData.append("tshirts", state.tshirts);

            Axios
                .post("api/operator/booking/create", formData)
                .then((res) => {
                    if (res.data.response == "success") {
                        showToast("success", "Success", res.data.message);
                        router.replace({ name: 'booking', params: { slug: route.params.slug, id: route.params.id } });
                    } else {
                        showToast("error", "Error", res.data.message);
                        console.log(res);
                    }
                    bookingLoading.value = false;
                })
                .catch((err) => {
                    bookingLoading.value = false;
                    console.log(err);
                    showToast("error", "Error", "Booking failed. Try again!");
                });
        }

        const showToast = (severity, summary, detail) => {
            toast.add({severity: severity, summary: summary, detail: detail, life: 5000});
        };
        
        const formatMobileNumber = (number) => {
            return '0' + number.toString().slice(-10);
        };

        return {
            route,
            pageLoading,
            user,
            tour,
            getUserByEmail,
            searchByEmail,
            newUser,
            tshirtSizes,
            paymentMethod,
            couponInput,
            couponApplied,
            coupon,
            couponLoading,
            couponSubmitted,
            couponShowMessage,
            couponState,
            c$,

            bookingLoading,
            bookingSubmitted,
            bookingShowMessage,
            state,
            v$,

            openCouponInput,
            closeCouponInput,
            toggleCouponInput,
            applyCoupon,
            removeCoupon,
            maxAdultsSeatsAvailable,
            maxChildrenSeatsAvailable,
            calculateDiscount,
            cancel,
            updatePrice,
            booking,
            // profile,
            showToast,
            backButton,
            formatMobileNumber
        };
    },
}
</script>

<style lang="scss" scoped>
.p-fieldset {
    padding: 0 !important;
}
.p-fieldset.p-fieldset-legend {
    padding: 0 !important;
}
.p-fieldset.p-fieldset-content {
    padding: 0 !important;
}
</style>
